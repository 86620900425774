// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './Auth';
import AppRoutes from './AppRoutes';
import Footer from './Footer';
import styled from 'styled-components';
import globalState from './globalState';
import { NotificationProvider } from './NotificationContext';
/* import Notification from './Notification'; */
import './Notification.css'; // Importiere die Notification CSS

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
  background: #f4f4f4;
`;

const App = () => {
  useEffect(() => {
    globalState.setUserID();
  }, []);

  return (
    <Router>
      <AuthProvider>
        <NotificationProvider>
          <PageLayout>
            <AppRoutes />
            <Footer />
          </PageLayout>
        </NotificationProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
