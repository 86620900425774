import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Container, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Auth'; // Correct import of useAuth
import apiUrl from './utils';
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei
import { formatDateGerman } from './utils';

function AgreementsManagement() {
  const { user } = useAuth();
  const storeEmail = user.email;
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();

  const [agreements, setAgreements] = useState([]);
  const [dealers, setDealers] = useState([]);

  useEffect(() => {
    fetchAgreements();
    fetchDealers();
  }, []);

  const fetchAgreements = async () => {
    const response = await axios.get(`${apiUrl}/api/agreements/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setAgreements(response.data);
  };

  const fetchDealers = async () => {
    const response = await axios.get(`${apiUrl}/api/dealers/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDealers(response.data);
  };

  const handleAddAgreement = () => {
    navigate('/add-agreement');
  };

  return (
    <Container>
      <h1>Vereinbarungen</h1>
      <button class='button' variant="primary" onClick={handleAddAgreement}>
        Neue Vereinbarung anlegen
      </button>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Händler</th>
            <th>Start</th>
            <th>Ende</th>
            <th>Laufzeit</th>
            <th>Kündigungsfrist</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agreements.map((agreement, index) => (
            <tr key={index}>
              <td>{agreement.custom_number}</td>
              <td>{dealers.find(dealer => dealer.id === agreement.dealer_id)?.first_name} {dealers.find(dealer => dealer.id === agreement.dealer_id)?.last_name} - {dealers.find(dealer => dealer.id === agreement.dealer_id)?.city}</td>
              <td>{formatDateGerman(agreement.start_date)}</td>
              <td>{formatDateGerman(agreement.end_date)}</td>
              <td>{agreement.minimum_duration} Monate</td>
              <td>{agreement.notice_period} Wochen</td>
              <td>
                <button className='button' variant="secondary" onClick={() => navigate(`/edit-agreement/${agreement.id}`)}>
                  Aufrufen
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default AgreementsManagement;
