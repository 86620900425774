import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './Auth';
import globalState from './globalState';
import apiUrl from './utils';

const navItemStyles = css`
  padding: 10px;
  text-decoration: none;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  display: inline-flex;
  align-items: center;
  &:hover {
    background: #76B9AF;
  }
`;

const Header = styled.header`
  background-color: #022f40;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 20px;
`;

const NavContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    display: ${({ isMobileOpen }) => (isMobileOpen ? 'flex' : 'none')};
    width: 100%;
  }
`;

const NavItem = styled(Link)`
  ${navItemStyles}
  position: relative;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const AdminNavItem = styled(NavItem)`
  color: #ffcc00;
`;

const NavButton = styled.button`
  ${navItemStyles}

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const BurgerMenu = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    margin-left: auto;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #022f40;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  flex-direction: column;
  z-index: 1000;

  @media (max-width: 768px) {
    left: 10px;
    position: static;
    width: 100%;
  }
`;

const DropdownToggle = styled.span`
  margin-left: 5px;
`;

const MobileMenu = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: ${({ isMobileOpen }) => (isMobileOpen ? 'block' : 'none')};
    width: 100%;
    position: absolute;
    background-color: #022f40;
    top: 60px;
    left: 0;
    z-index: 1000;
  }
`;

const DropdownItem = styled(Link)`
  ${navItemStyles}
`;

const HeaderNav = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [settings, setSettings] = useState({
    user_type: 1
  });

  const toggleDropdown = (menu) => {
    setDropdownOpen(prevState => {
      const isOpen = prevState[menu];
      const newState = { ...prevState, [menu]: !isOpen };
      Object.keys(prevState).forEach(key => {
        if (key !== menu) {
          newState[key] = false;
        }
      });
      return newState;
    });
  };

  const closeDropdown = () => {
    setDropdownOpen({});
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.post(`${apiUrl}/api/get-user-settings`, { token: localStorage.getItem('access_token') });
        setSettings(response.data);
      } catch (error) {
        console.error('Failed to fetch user settings.', error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    closeDropdown();
    setIsMobileOpen(false);
  }, [location.pathname]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isAdmin = globalState.userID && (globalState.email.endsWith('@wasschber.de') || globalState.email.endsWith('@schmidt-it.services'));

  return (
    <Header>
      <Logo src="https://wasschber.de/wp-content/uploads/2023/12/WASSCHBER_weiss_bunt.png" alt="Logo" />
      <BurgerMenu onClick={() => {
        setIsMobileOpen(!isMobileOpen);
        console.log('Burger menu clicked, isMobileOpen:', !isMobileOpen);
      }}>
        ☰
      </BurgerMenu>
      <NavContainer>
        <NavItem to="/dashboard" onClick={closeDropdown}>Dashboard</NavItem>
        <NavItem to="/settings" onClick={closeDropdown}>Profil</NavItem>
        {(settings.user_type === 1 || settings.user_type === 3) &&
          <NavItem to="#" onClick={() => toggleDropdown('dealer')}>
            Händler-Cockpit
            <DropdownToggle>▼</DropdownToggle>
            <DropdownMenu isOpen={dropdownOpen['dealer']}>
              <DropdownItem to="/products" onClick={closeDropdown}>Produkte</DropdownItem>
              <DropdownItem to="/agreements-view" onClick={closeDropdown}>Vereinbarungen</DropdownItem>
              <DropdownItem to="/deliverynote" onClick={closeDropdown}>Lieferscheine</DropdownItem>
              <DropdownItem to={`/inventoryoverview?viewType=merchant&user_id=${globalState.userID}`} onClick={closeDropdown}>Bestände</DropdownItem>
              <DropdownItem to={`/salesoverview?viewType=merchant&user_id=${globalState.userID}`} onClick={closeDropdown}>Verkäufe</DropdownItem>
              <DropdownItem to="/dealer-billing" onClick={closeDropdown}>Abrechnungsübersicht</DropdownItem>
            </DropdownMenu>
          </NavItem>
        }
        {(settings.user_type === 2 || settings.user_type === 3) &&
          <NavItem to="#" onClick={() => toggleDropdown('store')}>
            Store-Verwaltung
            <DropdownToggle>▼</DropdownToggle>
            <DropdownMenu isOpen={dropdownOpen['store']}>
              <DropdownItem to="/store-settings" onClick={closeDropdown}>Einstellungen</DropdownItem>
              <DropdownItem to="/service-management" onClick={closeDropdown}>Dienstleistungen & Services</DropdownItem>
              <DropdownItem to="/dealer-management" onClick={closeDropdown}>Händlerverwaltung</DropdownItem>
              <DropdownItem to="/agreements" onClick={closeDropdown}>Händlervereinbarungen</DropdownItem>
              <DropdownItem to="/productoverview" onClick={closeDropdown}>Produktübersicht</DropdownItem>
              <DropdownItem to="/deliverynotestore" onClick={closeDropdown}>Lieferungen</DropdownItem>
              <DropdownItem to={`/inventoryoverview?viewType=store&user_id=${globalState.userID}`} onClick={closeDropdown}>Bestände</DropdownItem>
              <DropdownItem to={`/salesoverview?viewType=store&user_id=${globalState.userID}`} onClick={() => setIsMobileOpen(false)}>Verkäufe</DropdownItem>
              <DropdownItem to="/billingoverview" onClick={() => setIsMobileOpen(false)}>Abrechnungen</DropdownItem>
            </DropdownMenu>
          </NavItem>
        }
        {/* <NavItem to="/contact" onClick={closeDropdown}>Contact</NavItem> */}
        {isAdmin && <AdminNavItem to="/admin">Admin</AdminNavItem>}
        <NavButton onClick={handleLogout}>Logout</NavButton>
      </NavContainer>
      <MobileMenu isMobileOpen={isMobileOpen}>
        <NavItem to="/dashboard" onClick={() => setIsMobileOpen(false)}>Dashboard</NavItem>
        <NavItem to="/settings" onClick={() => setIsMobileOpen(false)}>Profil</NavItem>
        {(settings.user_type === 1 || settings.user_type === 3) && (
          <>
            <NavItem to="#" onClick={() => toggleDropdown('dealer')}>
              Händler-Cockpit
              <DropdownToggle>▼</DropdownToggle>
            </NavItem>
            <DropdownMenu isOpen={dropdownOpen['dealer']}>
              <DropdownItem to="/products" onClick={() => setIsMobileOpen(false)}>Produkte</DropdownItem>
              <DropdownItem to="/agreements-view" onClick={() => setIsMobileOpen(false)}>Vereinbarungen</DropdownItem>
              <DropdownItem to="/deliverynote" onClick={closeDropdown}>Lieferscheine</DropdownItem>
              <DropdownItem to={`/inventoryoverview?viewType=merchant&user_id=${globalState.userID}`} onClick={closeDropdown}>Bestände</DropdownItem>              
              <DropdownItem to={`/salesoverview?viewType=merchant&user_id=${globalState.userID}`} onClick={() => setIsMobileOpen(false)}>Verkäufe</DropdownItem>
              <DropdownItem to="/dealer-billing" onClick={() => setIsMobileOpen(false)}>Abrechnungsübersicht</DropdownItem>
            </DropdownMenu>
          </>
        )}
        {(settings.user_type === 2 || settings.user_type === 3) && (
          <>
            <NavItem to="#" onClick={() => toggleDropdown('store')}>
              Store-Verwaltung
              <DropdownToggle>▼</DropdownToggle>
            </NavItem>
            <DropdownMenu isOpen={dropdownOpen['store']}>
              <DropdownItem to="/store-settings" onClick={() => setIsMobileOpen(false)}>Einstellungen</DropdownItem>
              <DropdownItem to="/service-management" onClick={() => setIsMobileOpen(false)}>Dienstleistungen & Services</DropdownItem>
              <DropdownItem to="/dealer-management" onClick={() => setIsMobileOpen(false)}>Händlerverwaltung</DropdownItem>
              <DropdownItem to="/agreements" onClick={() => setIsMobileOpen(false)}>Händlervereinbarungen</DropdownItem>
              <DropdownItem to="/productoverview" onClick={closeDropdown}>Produktübersicht</DropdownItem>
              <DropdownItem to="/deliverynotestore" onClick={closeDropdown}>Lieferungen</DropdownItem>
              <DropdownItem to={`/inventoryoverview?viewType=store&user_id=${globalState.userID}`} onClick={closeDropdown}>Bestände</DropdownItem>
              <DropdownItem to={`/salesoverview?viewType=store&user_id=${globalState.userID}`} onClick={() => setIsMobileOpen(false)}>Verkäufe</DropdownItem>
              <DropdownItem to="/billingoverview" onClick={() => setIsMobileOpen(false)}>Abrechnungen</DropdownItem>
            </DropdownMenu>
          </>
        )}
        {/* <NavItem to="/contact" onClick={() => setIsMobileOpen(false)}>Contact</NavItem> */}
        <NavButton onClick={handleLogout}>Logout</NavButton>
      </MobileMenu>
    </Header>
  );
};

export default HeaderNav;
