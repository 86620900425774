import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './Auth';
import CryptoJS from 'crypto-js';
import styled from 'styled-components';
import globalState from './globalState';
import apiUrl from './utils';

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #f4f4f4;
`;

const LoginBox = styled.div`
  padding: 40px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 440px;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #022f40; // Textfarbe
`;

const LinksContainer = styled.div`
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  color: #00A4D5; // Linkfarbe
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
  background: #f4f4f4;
`;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');

    if (emailParam) {
      setEmail(emailParam);
    }

    const notificationCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('notification='));
    if (notificationCookie) {
      setNotification(decodeURIComponent(notificationCookie.split('=')[1]));
      document.cookie = 'notification=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }

    if (notification) {
      const timer = setTimeout(() => setNotification(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [location, notification]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const encryptPassword = (password) => {
    const secretKey = CryptoJS.enc.Utf8.parse('my-secret-key-1234567890'); // 16 Zeichen langer Schlüssel
    const iv = CryptoJS.lib.WordArray.random(16); // Generiere einen zufälligen 16-Byte-IV

    const encrypted = CryptoJS.AES.encrypt(password, secretKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC, // Verwenden Sie CBC-Modus
      padding: CryptoJS.pad.Pkcs7
    });

    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const ciphertextBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return ivBase64 + ':' + ciphertextBase64;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset error
    const encryptedPassword = encryptPassword(password);
    try {
      const response = await axios.post(`${apiUrl}/api/login/`, { email, password: encryptedPassword });
      const { access_token } = response.data;
      localStorage.setItem('access_token', access_token);
      console.log("Token created")

      await globalState.setUserID();
      login(access_token);
      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.detail);
        if (error.response.data.detail === "Der Account ist nicht aktiv.") {
          const encodedEmail = encodeURIComponent(email);
          navigate(`/resend-activation?email=${encodedEmail}`)
        }
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };


  return (
    <Content>
      <LoginContainer>
        <LoginBox>
          <Title>Login</Title>
          {notification && <div className="notification">{notification}</div>}
          {error && <div className="error">{error}</div>}
          <form onSubmit={handleSubmit}>
            <input className='input'
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input className='input'
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button className='button' type="submit">Login</button>
          </form>
          <LinksContainer>
            <StyledLink to="/register">Registrieren</StyledLink>
            <StyledLink to="/forgot-password">Passwort vergessen?</StyledLink>
          </LinksContainer>
        </LoginBox>
      </LoginContainer>
    </Content>
  );
};

export default Login;
