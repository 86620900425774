// src/components/SalesOverview.js

import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './WasSchBer.css'; // Importieren Sie die Stylesheet-Datei
import apiUrl from './utils';
import globalState from './globalState';

const SalesOverview = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [stores, setStores] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [billingStatus, setBillingStatus] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const viewType = searchParams.get('viewType'); // "merchant" oder "store"
  const userId = globalState.userID;

  useEffect(() => {
    fetchSales();
    fetchFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [selectedStore, selectedMerchant, billingStatus, month, year, sales]);

  const fetchSales = async () => {
    try {
      let response;
      if (viewType === 'merchant') {
        response = await axios.get(`${apiUrl}/api/sales/merchant/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
      } else {
        response = await axios.get(`${apiUrl}/api/sales/store/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
      }
      setSales(response.data);
      console.log(response.data)
      setFilteredSales(response.data); // Initialize filteredSales with all sales
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchFilters = async () => {
    try {
      const storeResponse = await axios.get(`${apiUrl}/api/stores`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setStores(storeResponse.data);
      

      const merchantResponse = await axios.get(`${apiUrl}/api/dealers`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setMerchants(merchantResponse.data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  const applyFilters = () => {
    let filtered = sales.filter(sale => {
      const saleDate = new Date(sale.sale_date);
      const saleMonth = saleDate.getMonth() + 1;
      const saleYear = saleDate.getFullYear();

      const matchesStore = selectedStore ? sale.store_name === selectedStore.label : true;
      const matchesMerchant = selectedMerchant ? `${sale.merchant_first_name} ${sale.merchant_last_name} - ${sale.merchant_city}`  === selectedMerchant.label : true;
      const matchesBillingStatus = billingStatus ? sale.billingStatus === billingStatus.value : true;
      const matchesMonth = month ? (month.value === 'all' || saleMonth === month.value) : true;
      const matchesYear = saleYear === year;

      return matchesStore && matchesMerchant && matchesBillingStatus && matchesMonth && matchesYear;
    });

    setFilteredSales(filtered);
  };

  const handleStoreChange = (selectedOption) => {
    setSelectedStore(selectedOption);
  };

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption);
  };

  const handleBillingStatusChange = (selectedOption) => {
    setBillingStatus(selectedOption);
  };

  const handleMonthChange = (selectedOption) => {
    setMonth(selectedOption);
  };

  const handleYearChange = (e) => {
    setYear(parseInt(e.target.value));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getStatusVariant = (status) => {
    switch (status) {
      case 'open':
        return 'warning';
      case 'billed':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const monthOptions = [
    { value: 'all', label: 'Gesamtes Jahr' },
    { value: 1, label: 'Januar' },
    { value: 2, label: 'Februar' },
    { value: 3, label: 'März' },
    { value: 4, label: 'April' },
    { value: 5, label: 'Mai' },
    { value: 6, label: 'Juni' },
    { value: 7, label: 'Juli' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'Oktober' },
    { value: 11, label: 'November' },
    { value: 12, label: 'Dezember' },
  ];

  const paginatedSales = filteredSales.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container>
      <h1>Verkaufsübersicht</h1>
      <Form>
        <Row>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Monat</Form.Label>
              <Select
                options={monthOptions}
                value={month}
                onChange={handleMonthChange}
                placeholder="Monat auswählen..."
                isClearable
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Jahr</Form.Label>
              <Form.Control as="select" value={year} onChange={handleYearChange}>
                {[2024, 2025, 2026].map(y => (
                  <option key={y} value={y}>{y}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          {viewType === 'merchant' ? (
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Store</Form.Label>
                <Select
                  options={stores.map(store => ({ value: store.id, label: store.name }))}
                  value={selectedStore}
                  onChange={handleStoreChange}
                  placeholder="Store auswählen..."
                  isClearable
                />
              </Form.Group>
            </Col>
          ) : (
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Händler</Form.Label>
                <Select
                  options={merchants.map(merchant => ({
                    value: merchant.id,
                    label: `${merchant.first_name} ${merchant.last_name} - ${merchant.city}`
                  }))}
                  value={selectedMerchant}
                  onChange={handleMerchantChange}
                  placeholder="Händler auswählen..."
                  isClearable
                />
              </Form.Group>
            </Col>
          )}
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Abrechnungsstatus</Form.Label>
              <Select
                options={[
                  { value: 'open', label: 'Offen' },
                  { value: 'billed', label: 'Abgerechnet' },
                ]}
                value={billingStatus}
                onChange={handleBillingStatusChange}
                placeholder="Status auswählen..."
                isClearable
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Table bordered className="mt-3">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Uhrzeit</th>
            <th>{viewType === 'merchant' ? 'Store' : 'Händler'}</th>
            <th>Produkt</th>
            <th>Menge</th>
            <th>Einzelpreis</th>
            <th>Betrag</th>
            <th>Abrechnungsstatus</th>
            <th>Abrechnungsbeleg</th>
          </tr>
        </thead>
        <tbody>
          {paginatedSales.map((sale) => (
            <tr key={sale.id}>
              <td data-label="Datum">{new Date(sale.sale_date).toLocaleDateString()}</td>
              <td data-label="Uhrzeit">{new Date(sale.sale_date).toLocaleTimeString()}</td>
              <td>{viewType === 'merchant' ? sale.store_name : `${sale.merchant_first_name} ${sale.merchant_last_name} - ${sale.merchant_city}`}</td>
              <td data-label="Produkt">{sale.product_name}</td>
              <td data-label="Menge">{sale.quantity}</td>
              <td data-label="Einzelpreis">{sale.price.toFixed(2)} €</td>
              <td data-label="Gesamtpreis">{sale.total.toFixed(2)} €</td>
              <td data-label="Abrechnungsstatus">
                <Button variant={getStatusVariant(sale.billing_status)} disabled>
                  {sale.billing_status === 'open' ? 'Offen' : 'Abgerechnet'}
                </Button>
              </td>
              <td>{sale.billing_status === 'billed' ? `2024-${sale.id}` : ''}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination">
        {[...Array(Math.ceil(filteredSales.length / itemsPerPage)).keys()].map(page => (
          <button className='button'
            key={page + 1}
            onClick={() => handlePageChange(page + 1)}
            active={currentPage === page + 1}
          >
            {page + 1}
          </button>
        ))}
      </div>
    </Container>
  );
};

export default SalesOverview;
