// src/components/InventoryOverview.js

import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './WasSchBer.css'; // Importieren Sie die Stylesheet-Datei
import globalState from './globalState'; // Importieren Sie globalState
import apiUrl from './utils';

const InventoryOverview = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [stores, setStores] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const viewType = searchParams.get('viewType'); // "merchant" oder "store"
  const userId = searchParams.get('user_id'); // User ID

  useEffect(() => {
    fetchSettings();
    fetchFilters();
  }, []);
  
  useEffect(() => {
    if (storeId !== null) {
      fetchInventory();
    }
  }, [storeId]);

  useEffect(() => {
    applyFilters();
  }, [selectedStore, selectedMerchant, selectedCategory]);

  useEffect(() => {
    applySorting();
  }, [sortConfig]);

  const fetchSettings = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/get-store-settings`, { user_id: globalState.userID }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      setStoreId(response.data.id);
    } catch (error) {
      console.error('Fehler beim Abrufen der Store-Einstellungen:', error);
    }
  };

  const fetchFilters = async () => {
    try {
      const storeResponse = await axios.get(`${apiUrl}/api/stores`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setStores(storeResponse.data);

      const merchantResponse = await axios.get(`${apiUrl}/api/dealers/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setMerchants(merchantResponse.data);

      const categoryResponse = await axios.get(`${apiUrl}/api/categories/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setCategories(categoryResponse.data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  const fetchInventory = async () => {
    try {
      let response;
      if (viewType === 'merchant') {
        response = await axios.get(`${apiUrl}/api/inventory/merchant/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
      } else {
        response = await axios.get(`${apiUrl}/api/inventory/store/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
      }
      setInventoryData(response.data);
      setFilteredInventory(response.data);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };

  const applyFilters = () => {
    let filtered = inventoryData.filter(item => {
      return (
        (!selectedStore || item.store === selectedStore.label) &&
        (!selectedMerchant || item.merchant === selectedMerchant.label) &&
        (!selectedCategory || item.category === selectedCategory.label)
      );
    });

    setFilteredInventory(filtered);
    applySorting(filtered);
  };

  const applySorting = (data = filteredInventory) => {
    if (sortConfig.key) {
      const sortedData = [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setFilteredInventory(sortedData);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleStoreChange = (selectedOption) => {
    setSelectedStore(selectedOption);
    applyFilters();
  };

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption);
    applyFilters();
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    applyFilters();
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };

  return (
    <Container>
      <h1>Bestandsübersicht</h1>
      <Form>
        <Row>
          {viewType === 'merchant' ? (
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Store</Form.Label>
                <Select
                  options={stores.map(store => ({ value: store.id, label: store.name }))}
                  value={selectedStore}
                  onChange={handleStoreChange}
                  placeholder="Store auswählen..."
                  isClearable
                />
              </Form.Group>
            </Col>
          ) : (
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Händler</Form.Label>
                <Select
                  options={merchants.map(merchant => ({
                    value: merchant.id,
                    label: `${merchant.first_name} ${merchant.last_name} - ${merchant.city} - ${merchant.email}`
                  }))}
                  value={selectedMerchant}
                  onChange={handleMerchantChange}
                  placeholder="Händler auswählen..."
                  isClearable
                />
              </Form.Group>
            </Col>
          )}
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Kategorie</Form.Label>
              <Select
                options={categories.map(category => ({ value: category.id, label: category.name }))}
                value={selectedCategory}
                onChange={handleCategoryChange}
                placeholder="Kategorie auswählen..."
                isClearable
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Table bordered className="mt-3">
        <thead>
          <tr>
            {viewType === 'merchant' ? <th onClick={() => handleSort('store')}>Store {getSortIndicator('store')}</th> : <th onClick={() => handleSort('merchant')}>Händler {getSortIndicator('merchant')}</th>}
            <th onClick={() => handleSort('name')}>Name {getSortIndicator('name')}</th>
            <th onClick={() => handleSort('category')}>Kategorie {getSortIndicator('category')}</th>
            <th onClick={() => handleSort('delivered')}>Geliefert {getSortIndicator('delivered')}</th>
            <th onClick={() => handleSort('sold')}>Verkauft {getSortIndicator('sold')}</th>
            <th onClick={() => handleSort('currentStock')}>Aktueller Bestand {getSortIndicator('currentStock')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredInventory.map((item) => (
            <tr key={item.id}>
              {viewType === 'merchant' ? <td data-label="Store">{item.store}</td> : <td data-label="Händler">{item.merchant}</td>}
              <td data-label="Name">{item.name}</td>
              <td data-label="Kategorie">{item.category}</td>
              <td data-label="Geliefert">{item.delivered}</td>
              <td data-label="Verkauft">{item.sold}</td>
              <td data-label="Aktueller Bestand">{item.delivered - item.sold}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default InventoryOverview;
