import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Container, Button, Form, Table, Modal } from 'react-bootstrap';
import { useAuth } from './Auth';
import apiUrl from './utils';

const token = "your-jwt-token"; // Set your JWT token here

function DealerManagement() {
  const { user } = useAuth();
  const [dealers, setDealers] = useState([]);

  useEffect(() => {
    fetchDealers();
  }, []);

  const fetchDealers = async () => {
    const response = await axios.get(`${apiUrl}/api/dealers/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
    setDealers(response.data);
    console.log(dealers)
  };

  return (
    <Container>
      <h1>Händlerübersicht</h1>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Firmenname</th>
            <th>Straße</th>
            <th>Hausnummer</th>
            <th>PLZ</th>
            <th>Stadt</th>
            <th>E-Mail</th>
            <th>Mobil</th>
          </tr>
        </thead>
        <tbody>
          {dealers.map((dealer, index) => (
            <tr key={index}>
              <td>{dealer.first_name}</td>
              <td>{dealer.last_name}</td>
              <td>{dealer.company_name}</td>
              <td>{dealer.street}</td>
              <td>{dealer.house_number}</td>
              <td>{dealer.postal_code}</td>
              <td>{dealer.city}</td>
              <td>{dealer.email}</td>
              <td>{dealer.phone}</td>
            </tr>
          ))}
        </tbody>
      </Table>

    </Container>
  );
}

export default DealerManagement;
