import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei
import apiUrl from './utils';
import { NotificationContext } from './NotificationContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #f4f4f4;
`;

const Box = styled.div`
  padding: 40px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 440px;
  text-align: center;
`;
const Title = styled.h2`
  text-align: center;
  color: #022f40;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SuccessMessage = styled.p`
  color: green;
`;

const ForgotPassword = () => {
  const { showNotification } = useContext(NotificationContext);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [notification, setNotification] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/users/reset-password/`, { email });
      showNotification('Passwort zurücksetzen E-Mail versendet, bitte prüfe deine E-Mail-Inbox.', 'success');
      setTimeout(() => {
        navigate('/login');
      }, 3000);      
    } catch (error) {
      showNotification('Passwort zurücksetzen E-Mail konnte nicht versendet werden.', 'error');
    }
  };

  return (
    <Container>
        <Box>
      <Title>Passwort vergessen</Title>
      {notification && <Notification message={notification} />}
      <form onSubmit={handleResetPassword}>
        {message && <SuccessMessage>{message}</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <input className='input'
          type="email"
          placeholder="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className='button' type="submit">Passwort vergessen</button>
      </form>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
