import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import apiUrl from './utils';
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei
import { NotificationContext } from './NotificationContext';
//import { useTracking } from './TrackingContext'; // Importiere den Tracking-Hook
// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  background-color: #f4f4f4;
`;

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f4f4f4;
`;

const RegisterBox = styled.div`
  padding: 40px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 440px;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #022f40;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CheckboxContainer = styled.div`
  margin: 10px 0;
  text-align: left;
`;

const LinksContainer = styled.div`
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  color: #00A4D5;
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const InfoText = styled.p`
  margin-top: 20px;
  color: #022f40;
  font-size: 12px;
  text-align: center;
`;

const Footer = styled.footer`
  background-color: #022f40;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const PasswordInfo = styled.p`
  font-size: 12px;
  color: #666;
  text-align: left;
  margin-top: -10px;
`;

const PasswordContainer = styled.div`
  position: relative;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #007ea7;
`;

function Registration() {
    const { showNotification } = useContext(NotificationContext);
    //const { trackEvent } = useTracking();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const hasTracked = useRef(false);
    // Verwende useEffect, um das Tracking nur einmal bei der ersten Anzeige der Seite auszuführen
    //useEffect(() => {
    //    if (!hasTracked.current) { // Überprüfe, ob das Tracking bereits durchgeführt wurde
    //        trackEvent('Registrierung aufgerufen', {
    //            userID: 0,
    //            function: "Registrierung aufgerufen",
    //            screen_width: window.screen.width,
    //            screen_height: window.screen.height
    //        });
    //        hasTracked.current = true; // Setze das Flag auf true, nachdem das Tracking erfolgt ist
    //    }
    //}, [trackEvent]);



    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        // Validierung der Passwörter
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.\-])[A-Za-z\d@$!%*?&#.\-]{8,}$/;
        if (!passwordRegex.test(password)) {
            showNotification('Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und eines der folgenden Sonderzeichen @$!%*?&#.- enthalten.', 'error');
            return;
        }

        if (password !== confirmPassword) {
            showNotification('Passwörter stimmen nicht überein.', 'error');
            return;
        }

        if (!acceptTerms) {
            showNotification('Bitte akzeptiere die Teilnahmebedingungen.', 'error');
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/users/`, {
                email,
                password,
                confirm_password: confirmPassword
            });

            showNotification('Erfolgreich registriert, bitte prüfe deine E-Mails.', 'success');
        } catch (error) {
            if (error.response) {
                const errorDetail = error.response.data.detail;
                if (typeof errorDetail === 'string') {
                    setMessage(errorDetail);
                } else if (Array.isArray(errorDetail)) {
                    const errorMessages = errorDetail.map((err) => err.msg).join(", ");
                    setMessage(errorMessages);
                } else {
                    showNotification('Es ist ein Fehler aufgetreten. Versuch es noch einmal.', 'error');
                }
            } else {
                showNotification('Es ist ein Fehler aufgetreten. Versuch es noch einmal.', 'error');
            }
        }
    };

    return (
        <PageContainer>
            <RegisterContainer>
                <RegisterBox>
                    <Title>Als Händler registrieren</Title>
                    <form onSubmit={handleSubmit}>
                        {message && <ErrorMessage>{message}</ErrorMessage>}
                        <Input
                            type="email"
                            placeholder="E-Mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <PasswordContainer>
                            <Input
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Passwort"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <PasswordToggle type="button" onClick={() => setPasswordVisible(!passwordVisible)}>
                                {passwordVisible ? "Verbergen" : "Anzeigen"}
                            </PasswordToggle>
                        </PasswordContainer>
                        <PasswordContainer>
                            <Input
                                type={confirmPasswordVisible ? "text" : "password"}
                                placeholder="Passwort bestätigen"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <PasswordToggle type="button" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
                                {confirmPasswordVisible ? "Verbergen" : "Anzeigen"}
                            </PasswordToggle>
                        </PasswordContainer>
                        <PasswordInfo>
                            Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.
                        </PasswordInfo>
                        <CheckboxContainer>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                    required
                                />
                                {' '}Ich akzeptiere die<StyledLink to="/terms"> Teilnahmebedingungen</StyledLink>
                            </label>
                        </CheckboxContainer>
                        <button className='button'  type="submit">Registrieren</button>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                    </form>
                    <LinksContainer>
                        <StyledLink to="/login">Du hast bereits einen Account? Einloggen.</StyledLink>
                    </LinksContainer>
                    <InfoText>
                        Mit der Registrierung akzeptierst du unsere Datenschutzbestimmungen.
                    </InfoText>
                </RegisterBox>
            </RegisterContainer>
        </PageContainer>
    );
}

export default Registration;