// src/components/DealerBillingOverview.js

import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import './WasSchBer.css'; // Importieren Sie die Stylesheet-Datei
import apiUrl, { openPdf } from './utils';
import globalState from './globalState';

const DealerBillingOverview = () => {
  const [billings, setBillings] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(0); // Default to "Gesamtes Jahr"
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const monthOptions = [
    { value: 0, label: 'Gesamtes Jahr' },
    { value: 1, label: 'Januar' },
    { value: 2, label: 'Februar' },
    { value: 3, label: 'März' },
    { value: 4, label: 'April' },
    { value: 5, label: 'Mai' },
    { value: 6, label: 'Juni' },
    { value: 7, label: 'Juli' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'Oktober' },
    { value: 11, label: 'November' },
    { value: 12, label: 'Dezember' },
  ];

  useEffect(() => {
    fetchBillings();
  }, [selectedMonth, selectedYear]);

  const fetchBillings = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/billing/dealer`, {
        month: selectedMonth,
        year: selectedYear,
        dealer_id: globalState.userID
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setBillings(response.data.billing_data);
    } catch (error) {
      console.error('Error fetching billings:', error);
    }
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const getStatusVariant = (status) => {
    switch (status) {
      case 'error':
        return 'primary';
      case 'offen':
        return 'warning';
      case 'bezahlt':
        return 'success';
      default:
        return 'secondary';
    }
  };

  return (
    <Container>
      <h1>Meine Abrechnungen</h1>
      <Form>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Monat</Form.Label>
              <Select
                options={monthOptions}
                value={monthOptions.find(option => option.value === selectedMonth)}
                onChange={handleMonthChange}
                placeholder="Monat auswählen..."
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Jahr</Form.Label>
              <Form.Control as="select" value={selectedYear} onChange={handleYearChange}>
                {[...Array(new Date().getFullYear() - 2019).keys()].map(y => (
                  <option key={2020 + y} value={2020 + y}>{2020 + y}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Table bordered className="mt-3">
        <thead>
          <tr>
            <th>Store</th>
            <th>Rechnungs-Nr.</th>
            <th>Abrechnungsmonat</th>
            <th>Abrechnungsjahr</th>
            <th>Gesamtbetrag</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {billings.map((billing) => (
            <tr key={billing.id}>
              <td>{billing.store_name}</td>
              <td>{billing.invoice_number}</td>
              <td>{billing.month}</td>
              <td>{billing.year}</td>
              <td>{billing.total_amount.toFixed(2)} € {' '}                  
                 <Button variant={getStatusVariant(billing.status)} disabled>
                    {billing.status}
                  </Button></td>
              <td>
                <button className='button' onClick={() => openPdf(billing.pdf)}>
                  PDF anzeigen
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default DealerBillingOverview;
